module.exports = [{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-130208-23"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"AppDevRocks","short_name":"AppDevRocks","start_url":"/","background_color":"#ffffff","theme_color":"#104aab","display":"minimal-ui","icon":"content/assets/icon.png","feeds":[{"query":"\n              allMarkdownRemark(\n                sort: { fields: [fields___date], order: DESC }\n                filter: { fields: { contentType: { eq: \"post\" } } }\n              ) {\n                edges {\n                  node {\n                    excerpt(pruneLength: 280)\n                    fields {\n                      slug\n                      date(formatString: \"MMMM DD, YYYY\")\n                      contentType\n                    }\n                    frontmatter {\n                      title\n                      banner {\n                        childImageSharp {\n                          fixed(width: 240, height: 120) {\n                          ...GatsbyImageSharpFixed\n                          }\n                        }\n                      }\n                    }\n                  }\n                }\n              }\n            "}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
