// custom typefaces
//require('prism-themes/themes/prism-atom-dark.css');

const target = '.post__comments',
  host = 'https://comments.appdev.rocks';

let injectedCommentsScript = false,
  isCommentsScriptReady = false;

const initComments = comments => {
  const slug = comments.getAttribute('data-slug');
  if (typeof window.Schnack !== 'undefined') {
    new window.Schnack({ target, slug, host,
      partials: {
        Preview: `Preview`,
        Edit: `Edit`,
        SendComment: `Send comment`,
        Cancel: `Cancel`,
        Or: `Or`,
        Mute: `Mute Notifications`,
        UnMute: `Unmute`,
        PostComment: `Post a comment. Markdown is supported!`,
        AdminApproval: `This comment is still waiting for your approval`,
        WaitingForApproval: `Your comment is still waiting for approval by the site owner`,
        SignInVia: `To post a comment you can sign in using one of these networks`,
        Reply: `<i class='icon schnack-icon-reply'></i> reply`,
        LoginStatus: "Signed in as <span class='schnack-user'>@%USER%</span> <small>(<a class='schnack-signout' href='#'>sign out</a>)</small>"
      }
    });
  }
};

exports.onInitialClientRender = exports.onRouteUpdate = () => {
  const injectCommentsScript = () => {
    // create script element
    const script = document.createElement(`script`);
    script.type = `text/javascript`;
    script.src = `${host}/client.js`;

    // listen for script load event
    script.onload = script.onreadystatechange = function() { // Attach handlers for all browsers
      if (!isCommentsScriptReady && (!this.readyState || this.readyState === "loaded" || this.readyState === "complete")) {
        isCommentsScriptReady = true;
        initComments(comments);
        script.onload = script.onreadystatechange = null; // Handle memory leak in IE
      }
    };

    // add script to head element
    document.getElementsByTagName(`head`)[0].appendChild(script);
  };

  const comments = document.querySelector(target);
  if (comments !== null) {
    if (!injectedCommentsScript) {
      injectedCommentsScript = true;

      injectCommentsScript();
    } else {
      initComments(comments);
    }
  }
};